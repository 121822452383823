import React from "react";
import styled from "styled-components";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  IoIosTimer,
  IoIosFlash,
  IoIosCash,
  IoIosHeart,
  IoIosHappy,
  IoIosArrowForward,
  IoMdOpen,
} from "react-icons/io";

import { useModal } from "../../components/useModal";

import Image from "../../components/image";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../../components/util";
import {
  IframeContainer,
  ResourceCard,
  CustomerQuote,
  CustomerQuoteLarge,
  Modal,
  CallToAction,
} from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import XeroLogo_Single from "../../images/logos/Xero/Xero_Blue_Single.svg";
import InvoxyLogo from "../../images/logos/Invoxy/Invoxy_Full.svg";
import DroppahLogo from "../../images/logos/Droppah/Droppah_Full_Black.svg";

const WebinarCard = styled(Box)`
  border-radius: ${(props) => props.theme.styles.borderRadius};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

  .gatsby-image-wrapper {
    border-radius: ${(props) => props.theme.styles.borderRadius}
      ${(props) => props.theme.styles.borderRadius} 0 0;
  }

  .content {
    padding: 30px;

    h3,
    p {
      color: #333;
    }

    p {
      margin-top: 10px;
    }
  }
`;

const TextCallout = styled.div`
  .gatsby-image-wrapper {
    margin-left: auto;
  }
`;

const CardRow = styled.div`
  a {
    width: 100%;
  }
`;

const FlexiTimeProducts = styled(Row)`
  > div {
    padding: 30px;
    text-align: center;
    border-radius: ${(props) => props.theme.styles.borderRadius};
    border: solid 1px ${(props) => props.theme.colours.greyDarken};

    img {
      margin-bottom: 30px;
      margin-top: 30px;
      max-width: 200px;
      width: 85%;
      max-height: 35px;
    }

    h2 {
      margin: 10px 0;
    }
  }
`;

const CA = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulResource(
        sort: { order: DESC, fields: publishedDate }
        filter: {
          resourceTitle: {
            in: ["Understanding New Zealand’s payroll compliance nightmare"]
          }
        }
      ) {
        edges {
          node {
            publishedDate
            resourceImage {
              gatsbyImageData(width: 650, layout: FIXED, quality: 100)
            }
            resourceTitle
            resourceType
            resourceTopic
            resourceContent {
              raw
            }
            excerpt
            url
            author
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="Chartered Accountants ANZ & FlexiTime Partnership | PayHero"
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={80} noPaddingBottom>
          <Box stackGap={50}>
            <Box stackGap={30}>
              <Image
                filename="CAandPayHero_Logo"
                alt="Chartered Accountants ANZ & PayHero Partnership"
                maxWidth={420}
                width="60%"
                centerImage
              />
              <h1 className="-textCenter -fontLarge">
                Payroll you and your <br className="hideOnMobile" />
                clients can trust
              </h1>
            </Box>
            <FlexButtons justify="center">
              <Button
                className="primary -lg gtm-cta"
                onClick={toggleModal}
                atag
              >
                Request a Call Back
              </Button>
              <Button
                className="grey -lg"
                to="https://partners.flexitime.works"
                atag
                externalLink
              >
                Become a Partner <IoMdOpen css={{ top: "2px" }} />
              </Button>
            </FlexButtons>
          </Box>
          <hr />
        </Wrapper>
      </Container>
      {/* <Container bg="darkBlue" className="-whiteOut">
        <Wrapper stackGap={25}>
          <h4 className="-fontNormal -textCenter">
            <IoIosPlayCircle css={{ top: "3px", position: "relative" }} />{" "}
            Upcoming on demand webinar series:{" "}
            <span className="-fontBold">
              Holidays Act Deep Dive - Provision, Portion & Payment.
            </span>{" "}
            <Link className="-fontBold" to="/ca/webinar-series">
              Register here
            </Link>
          </h4>
        </Wrapper>
      </Container> */}
      <Container>
        <Wrapper>
          <Row stackGap={80} alignCenter>
            <Box size={68} stackGap={40}>
              <div className="font-bump">
                <h2>Don't miss out on the payroll opportunity</h2>
                <p>
                  Payroll compliance is a hot topic with widespread reporting of
                  Holidays Act calculation issues and remediation payments.
                  According to MBIE, New Zealand workers are owed more than $2
                  billion in lost holiday pay, affecting up to one third of all
                  employees in the country.
                </p>
                <p>
                  Right now, payroll is a huge opportunity for savvy Chartered
                  Accountants.
                </p>
                <p>
                  One of the most common problems is using payroll systems that
                  accrue annual leave based on the hours or days an employee
                  works.
                </p>
                <p>
                  Following the very latest Holidays Act guidance from MBIE,{" "}
                  <Link to="/">PayHero</Link> stores employee leave balances in
                  weeks to make it easier for companies with staff who work
                  variable hours or have changing work patterns to get their
                  leave entitlements correct.
                </p>
              </div>
              <Button className="greyBlue -lg" to="/holidays-act-compliance">
                Payroll Compliance in PayHero
              </Button>
              <TextCallout css={{ marginTop: "0" }} className="hideOnMobile">
                <Image
                  filename="ReadMoreImage.png"
                  alt="Read More About Payroll Compliance"
                  maxWidth={200}
                />
              </TextCallout>
            </Box>
            <Box size={32}>
              <CardRow>
                {contentfulData.allContentfulResource.edges.map((edge, i) => {
                  return (
                    <ResourceCard
                      isFeatured={false}
                      key={i}
                      image={edge.node.resourceImage.gatsbyImageData}
                      type={edge.node.resourceType}
                      topic={edge.node.resourceTopic}
                      title={edge.node.resourceTitle}
                      excerpt={edge.node.excerpt}
                      date={edge.node.publishedDate}
                      url={edge.node.url}
                      words={edge.node.resourceContent.raw}
                      show={true}
                    />
                  );
                })}
              </CardRow>
            </Box>
          </Row>
          <CustomerQuoteLarge
            quote="We’re usually reluctant to endorse payroll software, simply because the cost of getting it wrong is huge. PayHero gets the detail right, at the same time as being exceptionally easy to use. In short, it’s second to none."
            name="Hamish Mexted"
            company="Convex Accounting"
            hero="HamishAndRiannFromConvex.jpg"
            pic="Hamish_Circle.png"
            logo="ConvexAccountingLogo.png"
            link="https://convexaccounting.co.nz/knowledge-library/xero-addon-payhero"
          />
        </Wrapper>
      </Container>
      <CallToAction
        title="Take PayHero for a test flight"
        description="Get a feel for PayHero with our free & fully interactive online demo."
        showDemoButton
        hideTrialButton
      />
      {/* <Container>
        <Wrapper>
          <div className="font-bump">
            <h2 className="-textCenter">Webinars</h2>
          </div>
          <Row stackGap={50}>
            <WebinarCard size={50}>
              <Image filename="CAWebinarBanner_3PS.png" alt="CA Webinar" />
              <Box className="content" stackGap={20}>
                <div className="badge -md -blue">On Demand Webinar Series</div>
                <Box stackGap={30}>
                  <div>
                    <h3>
                      <b>Holidays Act Deep Dive Webinar Series</b>
                    </h3>
                    <p>
                      In this webinar series we will look at the three key steps
                      to providing an employee’s entitlement to, and payment,
                      for annual holidays. Each webinar will be approximately 15
                      minutes long with a range of real life examples.
                    </p>
                  </div>
                  <Link
                    className="link-arrow link-floating"
                    to="/ca/webinar-series"
                  >
                    Register Here <IoIosArrowForward />
                  </Link>
                </Box>
              </Box>
            </WebinarCard>
            <WebinarCard size={50}>
              <Image filename="CAWebinarBanner_UHAC.png" alt="CA Webinar" />
              <Box className="content" stackGap={20}>
                <div className="badge -md -dark">On Demand Webinar</div>
                <Box stackGap={30}>
                  <div>
                    <h3>
                      <b>Untangling Holidays Act Compliance with PayHero</b>
                    </h3>
                    <p>
                      Learn how to answer the most common leave and payroll
                      compliance questions and help your clients move forward
                      with the confidence they’re paying their employees right.
                    </p>
                  </div>
                  <Link
                    className="link-arrow link-floating"
                    to="/ca/untangling-holidays-act"
                  >
                    Watch Webinar <IoIosArrowForward />
                  </Link>
                </Box>
              </Box>
            </WebinarCard>
          </Row>
        </Wrapper>
      </Container> */}
      <Container bg="lightGrey">
        <Wrapper>
          <Row className="font-bump" stackGap={60} alignCenter>
            <Box size={40}>
              <h2>Pay employees right with PayHero</h2>
              <p>
                PayHero is perfect for Kiwi companies that want stress-free and
                compliant <Link to="/payroll">online payroll</Link>. Especially
                those with part time and mobile workforces, who face unique
                challenges tracking employee time accurately and calculating the
                right holiday entitlements for their staff.
              </p>
              <p>
                Better yet, the key elements that feed into payroll are all
                included - employee{" "}
                <Link to="/leave-requests">leave management</Link>; fully
                automated <Link to="/payday-filing">payday filing</Link>;
                expense claims and reimbursements; automatic calculation of
                public holiday entitlements; rest and meal break management; and
                deep <Link to="/xero">integration with Xero</Link>.
              </p>
            </Box>
            <Box size={60}>
              <IframeContainer
                src="https://player.vimeo.com/video/369453779"
                title="Introducing PayHero | Online Payroll Made Super Easy"
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="Client feedback has been overwhelmingly positive, especially around time savings, simplifying public holiday payroll calculations, and generally reducing stress around payroll."
            name="Scotty Jenkin"
            company="Noone Plus"
            pic="PartnerCircle_Scotty.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <h2 className="-textCenter">
            The payroll partner you've been looking for
          </h2>
          <Box className="-textCenter" stackGap={40}>
            <Row stackGap={40}>
              <Box size={33}>
                <h3>
                  <IoIosTimer />
                  <br />
                  Time tools for any business
                </h3>
                <p>
                  PayHero streamlines the{" "}
                  <Link to="/timesheets">employee timesheet</Link> process with
                  online, mobile, GPS or clock in/out options delivering
                  accurate employee hours straight into payroll.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosFlash />
                  <br />
                  Put your payroll on AutoPilot
                </h3>
                <p>
                  <Link to="/blog/payroll-autopilot">
                    You can set your pays to run automatically
                  </Link>
                  . We’ll send the payroll admin or business owner an email with
                  all the details and they can finalise the pay without having
                  to login.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosCash />
                  <br />
                  Easy payments
                </h3>
                <p>
                  When you finalise a pay, transfer the funds with a{" "}
                  <Link to="/blog/pay-now">Pay Now POLi payment</Link>, or use
                  our industry standard bank batch file, leaving your clients in
                  complete control of their finances.
                </p>
              </Box>
            </Row>
            <Row stackGap={40}>
              <Box size={33}>
                <h3>
                  <IoIosHeart />
                  <br />
                  Free payroll & partner benefits
                </h3>
                <p>
                  Accounting firms that partner with PayHero get a free payroll
                  account for their practice and preferential pricing for
                  clients.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <img
                    className="-center"
                    src={XeroLogo_Single}
                    alt="Xero Integration | PayHero"
                    height={40}
                    css={{ marginBottom: "10px" }}
                  />
                  Deep Xero integration
                </h3>
                <p>
                  <Link to="/xero">Connect to Xero</Link> and automatically
                  assign wage costs to different account codes or tracking
                  categories.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosHappy />
                  <br />
                  Super support
                </h3>
                <p>
                  Our friendly local support team knows their stuff and partners
                  get priority support. With over ten years of NZ payroll
                  experience, we’ll help you with any curly questions.
                </p>
              </Box>
            </Row>
          </Box>
          <Image
            filename="PayHeroHero_Full.png"
            alt="PayHero Product Suite"
            maxWidth={1000}
            centerImage
            addShadow
          />
          <hr />
          <Row alignCenter stackGap={60}>
            <Box size={50} stackGap={40}>
              <div>
                <h4 className="h2">
                  Help your clients grow with software that supports their team
                  <br />
                  <span className="-fontLight">
                    FlexiTime builds software that simplifies employment. We
                    offer a range of integrated solutions to help businesses
                    manage and pay their employees.
                  </span>
                </h4>
              </div>
              <TextCallout className="hideOnMobile">
                <Image
                  filename="OurOtherProductsImage.png"
                  alt="FlexiTime Products"
                  maxWidth={300}
                />
              </TextCallout>
            </Box>
            <Box size={50}>
              <FlexiTimeProducts stackGap={30}>
                <Box size={50} stackGap={40} className="droppah-primary">
                  <div>
                    <span className="-fontBold">Scheduling</span>
                    <img
                      className="-center"
                      src={DroppahLogo}
                      alt="Droppah | Scheduling, timesheet & attendance software"
                    />
                    <h4>
                      Roster your staff and track time and attendance to control
                      costs & optimise staff coverage.
                    </h4>
                  </div>
                  <a
                    href="https://www.droppah.com"
                    target="_blank"
                    className="link-arrow -center"
                  >
                    Discover Droppah <IoMdOpen />
                  </a>
                </Box>
                <Box size={50} stackGap={40} className="invoxy-primary">
                  <div>
                    <span className="-fontBold">Billing</span>
                    <img
                      className="-center"
                      src={InvoxyLogo}
                      alt="Karmly | Contractor Management software"
                    />
                    <h4>
                      Increase billing accuracy and get paid faster with
                      timesheets, approvals & batch invoicing.
                    </h4>
                  </div>
                  <a
                    href="https://www.karmly.com/recruitment"
                    target="_blank"
                    className="link-arrow -center"
                  >
                    Discover Karmly <IoMdOpen />
                  </a>
                </Box>
              </FlexiTimeProducts>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="Secondary" className="-textCenter">
        <Wrapper stackGap={80}>
          <Box stackGap={40}>
            <h2 css={{ color: "#fff" }}>
              Compliant payroll you and your clients can trust
              <br />
              <span className="-fontLight">
                Find out more about PayHero or our partner programme.
              </span>
            </h2>
            <FlexButtons justify="center">
              <Button
                className="primary -lg gtm-cta"
                onClick={toggleModal}
                atag
              >
                Request a Call Back
              </Button>
              <Button
                className="grey -lg"
                to="https://partners.flexitime.works"
                atag
                externalLink
              >
                Become a Partner <IoMdOpen css={{ top: "2px" }} />
              </Button>
            </FlexButtons>
          </Box>
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default CA;
